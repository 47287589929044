import request from '@/router/axios';
import {businessUrl} from "@/config/env"


//查询车间
export const getWorkShop = () => {
    return request({
        url: businessUrl + "department/selectWorkShopName?type=1",
        method: "get",
        params: {
            type: 1
        }
    })
}


//查询工序
export const getProcess = (departmentId) => {
    return request({
        url: businessUrl + "process/selectByWorkShopId",
        method: "get",
        params: {
            departmentId
        }
    })
}

//查询设备
export const getMachine = (workShopId, processId) => {
    return request({
        url: businessUrl + "smallProgram/selectMachineByWorkShopIdAndProcessId",
        method: "get",
        params: {
            workShopId,
            processId
        }
    })
}

//查询品种
export const getProduct = () => {
    return request({
        url: businessUrl + "product/select",
        method: "post",
        data: {
        }
    })
}

//查询批号
export const getProductBatch = (productId) => {
    return request({
        url: businessUrl + "productBatch/selectBatch",
        method: "post",
        data: {
            productId
        }
    })
}

//获取数据集合
export const getProductMachineDataList = (startTime,endTime,workShopId,machineId,productid,batchid) => {
    return request({
        url: businessUrl + "productMachineData/selectListData",
        method: "post",
        data: {
            startTime,
            endTime,
            workShopId,
            machineId,
            productid,
            batchid
        }
    })
}

//获得预估产量
export const getForecastYield = (machineId,strTime,strIotNumber,endTime,endIotNumber,productId) => {
    return request({
        url: businessUrl + "publicMethods/calculateProduction",
        method: "get",
        params: {
            machineId,
            strTime,
            strIotNumber,
            endTime,
            endIotNumber,
            productId
        }
    })
}


//获得iot的米数差值
export const getDifferenceLength = (strTime, machineId, timeStrIotNumber, timeEndIotNumber, endTime) => {
    return request({
        url: businessUrl + "publicMethods/differenceLength",
        method: "post",
        data: {
            machineId,
            strTime,
            timeStrIotNumber,
            timeEndIotNumber,
            endTime,
        }
    })
}

//提交数据
export const commitMachineDateList = (row) => {
    return request({
        url: businessUrl + "productMachineData/addListData",
        method: "post",
        data:
        row
    })
}







